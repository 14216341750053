import React from "react"

import { Box, Container, Flex, Button, Text } from "components"

const CallToAction = ({ content }) => (
  <Box my={[5, 6]}>
    <Container>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        key={content.id}
      >
        <Text
          children={content.title}
          fontFamily="condensed"
          fontSize={[5, 6]}
          textAlign="center"
          mb={[4]}
        />
        <Button
          children={content.buttonText}
          as="a"
          href={content.buttonLink}
        />
      </Flex>
    </Container>
  </Box>
)

export default CallToAction
