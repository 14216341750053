import React from "react"
import ReactMarkdown from "react-markdown"
import css from "@styled-system/css"

import { Box, Container } from "components"

const TextBlock = ({ content, maxWidth = 600 }) => (
  <Box key={content.id} my={[5, 6]}>
    <Container maxWidth={maxWidth}>
      <ReactMarkdown
        source={content.text}
        css={css({
          "> p": {
            fontSize: 4,
          },
        })}
      />
    </Container>
  </Box>
)

export default TextBlock
